import { Alert, Button, Card, Col, Divider, Image, Row } from "antd";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import React from "react";
import _ from "lodash";
import { useLocation } from "react-router-dom";
import StoreBadge from "./StoreBadge";

const LoginForm = ({ login }) => {
  const { xs } = useBreakpoint();
  const location = useLocation();
  const search = new URLSearchParams(location.search);
  const hash = new URLSearchParams(location.hash);

  return (
    <Card
      bordered={false}
      style={{
        height: xs && "100%",
        minHeight: "60%",
        backgroundColor: "rgba(255, 255, 255, 0.8)",
      }}
      bodyStyle={{
        height: "100%",
      }}
    >
      <Row
        gutter={[24, 24]}
        style={{
          height: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Col>
          <Image
            preview={false}
            width={180}
            src="https://minio.fmc.fisheries.go.th/public/dof_logo2.png"
          />
        </Col>
        <Col>
          <div style={{ fontSize: "1.2rem" }}>Fisheries Monitoring Center</div>
        </Col>
        {(!_.isEmpty(hash.get("error_description")) ||
          !_.isEmpty(search.get("message"))) && (
          <Alert
            showIcon
            type="error"
            message={hash.get("error_description") || search.get("message")}
          />
        )}
        <Col>
          <Button
            type="primary"
            size="large"
            onClick={login}
            style={{ width: 240 }}
          >
            เข้าใช้งานระบบ
          </Button>
        </Col>
        <Divider plain>ใช้งานบนอุปกรณ์พกพา</Divider>
        <Col>
          <StoreBadge />
        </Col>
        <Col flex={1} />
        <div style={{ color: "gray" }}>
          &copy;{new Date().getFullYear()} Xsense Information Service Co., Ltd.
        </div>
      </Row>
    </Card>
  );
};

export default LoginForm;
