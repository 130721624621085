export default class ConfigProvider {
  static get CONFIG() {
    return {
      clientOrigin: "$REACT_APP_CLIENT_ORIGIN",
      jwtSecret: "$REACT_APP_JWT_SECRET",
      restUrl: "$REACT_APP_REST_URL",
      graphqlHttp: "$REACT_APP_GRAPHQL_HTTP",
      graphqlWs: "$REACT_APP_GRAPHQL_WS",
      clientId: "$REACT_APP_CLIENT_ID",
      authority: "$REACT_APP_AUTHORITY",
      redirectUri: "$REACT_APP_REDIRECT_URI",
      postLogoutRedirectUri: "$REACT_APP_POST_LOGOUT_REDIRECT_URI",
      silentRedirectUri: "$REACT_APP_SIRENT_REDIRECT_URI",
    };
  }

  static value(name) {
    if (!(name in this.CONFIG)) {
      return;
    }

    const value = this.CONFIG[name];

    if (!value) {
      return;
    }

    if (value.startsWith("$REACT_APP_")) {
      const envName = value.substr(1);
      const envValue = process.env[envName];
      if (envValue) {
        return envValue;
      } else {
        return;
      }
    } else {
      return value;
    }
  }
}
