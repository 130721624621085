import React, { useContext } from "react";
import { AuthenticationContext } from "@axa-fr/react-oidc-context";
import { Redirect } from "react-router-dom";
import { Alert, Col, Row } from "antd";
import Loading from "Components/Loading";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import LoginForm from "./LoginForm";

const Login = () => {
  const {
    oidcUser,
    isEnabled,
    isLoggingOut,
    isLoading,
    login,
    error,
  } = useContext(AuthenticationContext);
  const { xs } = useBreakpoint();

  if (isLoggingOut || isLoading) {
    return <Loading />;
  } else if (oidcUser || !isEnabled) {
    return <Redirect to="/" />;
  } else if (error) {
    return <Alert message={error} type="error" />;
  }

  return (
    <Row
      style={{
        height: "100%",
        backgroundImage:
          !xs &&
          `
            linear-gradient(to right top, rgba(100, 115, 201, 0.33), rgba(25, 32, 72, 0.7)),
            url(https://source.unsplash.com/random?fisheries)
          `,
        backgroundSize: "cover",
        backgroundPosition: "center center",
      }}
    >
      <Col
        flex={1}
        style={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Row
          style={{
            width: "100%",
            maxWidth: 1280,
          }}
        >
          <Col
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
            xs={{ span: 24 }}
            sm={{ span: 16, offset: 4 }}
            md={{ span: 12, offset: 10 }}
            lg={{ span: 10, offset: 12 }}
            xl={{ span: 8, offset: 14 }}
          >
            <LoginForm login={login} />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default Login;
