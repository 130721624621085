import React from "react";
import { Col, Row, Skeleton } from "antd";

const Loading = () => {
  return (
    <Row align="middle" style={{ height: "100%" }}>
      <Col
        xs={{ span: 20, offset: 2 }}
        md={{ span: 18, offset: 3 }}
        xl={{ span: 16, offset: 4 }}
        xxl={{ span: 14, offset: 5 }}
      >
        {Array(3)
          .fill("")
          .map((i, k) => (
            <Skeleton loading={true} active key={k} />
          ))}
      </Col>
    </Row>
  );
};

export default Loading;
