import Configuration from "Utils/configuration";

export const configuration = {
  isEnabled: true,
  configurations: [
    {
      origin: Configuration.value("clientOrigin"),
      config: {
        client_id: Configuration.value("clientId"),
        redirect_uri: Configuration.value("redirectUri"),
        response_type: "id_token token",
        post_logout_redirect_uri: Configuration.value("postLogoutRedirectUri"),
        scope: "openid profile email api offline_access",
        authority: Configuration.value("authority"),
        silent_redirect_uri: Configuration.value("silentRedirectUri"),
        automaticSilentRenew: true,
        loadUserInfo: true,
      },
    },
  ],
};

export const getPayload = (profile) => ({
  sub: profile.sub,
  name: profile.name,
  "https://hasura.io/jwt/claims": {
    "x-hasura-allowed-roles": [profile["fmc"]["role"]],
    "x-hasura-default-role": profile["fmc"]["role"],
    "x-hasura-user-id": profile.sub,
    ...(profile["fmc"]["role"] === "officer"
      ? { "x-hasura-org-id": profile["fmc"]["organization_id"] }
      : {}),
    ...(profile["fmc"]["role"] === "provider"
      ? { "x-hasura-provider-id": profile["fmc"]["provider_id"] }
      : {}),
    ...(profile["fmc"]["role"] === "owner"
      ? {
          "x-hasura-allowed-vessels": `{${profile["fmc"]["vessels"].join()}}`,
        }
      : {}),
  },
});
