import React, { Suspense, lazy } from "react";
import { ConfigProvider } from "antd";
import { Switch, Route, Redirect } from "react-router-dom";
import thTH from "antd/es/locale/th_TH";
import "moment/locale/th";
import Loading from "Components/Loading";

const Login = lazy(() => import("Pages/Login"));
const Wrapper = lazy(() => import("Pages/Wrapper"));
const Verify = lazy(() => import("Pages/Verify/Verify"));

function App() {
  return (
    <ConfigProvider locale={thTH} componentSize="small">
      <Suspense fallback={<Loading />}>
        <Switch>
          <Route path="/login">
            <Login />
          </Route>
          <Route path="/verify">
            <Verify />
          </Route>
          <Route path="/">
            <Wrapper />
          </Route>
          <Route path="*">
            <Redirect to="/login" />
          </Route>
        </Switch>
      </Suspense>
    </ConfigProvider>
  );
}

export default App;
