import { Space } from "antd";
import React from "react";

const StoreBadge = () => {
  return (
    <Space>
      <a href="https://apps.apple.com/us/app/fisheries-touch/id1001520715">
        <img
          src="https://minio.fmc.fisheries.go.th/public/badges/appstore_en.png"
          alt="Download on the App Store"
          style={{
            width: 134,
            height: 40,
          }}
        />
      </a>
      <a href="https://play.google.com/store/apps/details?id=com.dof.touch">
        <img
          alt="Get it on Google Play"
          src="https://minio.fmc.fisheries.go.th/public/badges/googleplay_en.png"
          style={{
            width: 134,
            height: 40,
          }}
        />
      </a>
    </Space>
  );
};

export default StoreBadge;
