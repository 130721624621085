import "Utils/ie.polyfills";
import React from "react";

import { render } from "react-dom";
import "./index.less";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { BrowserRouter } from "react-router-dom";
import { AuthenticationProvider, oidcLog } from "@axa-fr/react-oidc-context";
import Loading from "Components/Loading";
import { configuration } from "Utils/oidc";
import Login from "Pages/Login/Login";

const origin = document.location.origin;

const isEnabled = configuration.isEnabled;
if (configuration.configurations.length <= 0) {
  throw new Error(`No configuration found`);
}
const authenticationConfig = origin
  ? configuration.configurations.find((m) => m.origin === origin)
  : configuration.configurations[0];
if (!authenticationConfig) {
  throw new Error(`Configuration not found for origin ${origin}`);
}

const Start = () => (
  <BrowserRouter>
    <AuthenticationProvider
      notAuthenticated={Login}
      notAuthorized={Login}
      configuration={authenticationConfig.config}
      loggerLevel={oidcLog.ERROR}
      isEnabled={isEnabled}
      callbackComponentOverride={Loading}
    >
      <App />
    </AuthenticationProvider>
  </BrowserRouter>
);

render(<Start />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
